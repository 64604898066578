<template>
    <div class="novaMensagem">

        <InputText
            :label="`Nome do usuário`" 
            :preenchido="validacao.nomeUsuario"
            :disabled="!novoUsuario"
            v-model="form.nomeUsuario"
        />

        <InputText
            :label="`Email`" 
            :preenchido="validacao.email"
            v-model="form.email"
        />

        <InputText
            :label="`Número do telefone`" 
            :mascara="'+## (##) #####-####'"
            :preenchido="validacao.telefone"
            v-model="form.telefone"
        />

        <InputText
            v-if="novoUsuario"
            :label="`Senha provisória`" 
            :preenchido="validacao.senha"
            v-model="form.senha"
        />

        <InputText
            v-if="!novoUsuario"
            :label="`Alterar senha`" 
            :preenchido="validacao.senha"
            v-model="form.senha"
        />

        <SelectDefault 
            :label="`Grupo`" 
            :preenchido="validacao.grupo"
            v-model="form.grupo"
        >
            <option value="" selected disabled>Selecione o grupo</option>
            <option 
                v-for="(grupo, idxGrupo) in grupos"
                :key="grupo.nome"
                :value="grupo.nome"
            >
                {{ grupo.nome }}
            </option>
        </SelectDefault>

        <div v-if="!novoUsuario" style="display: flex; justify-content: space-between; align-items: center;">    
            <SwitchButton
                :label="`Status`" 
                :id="`status`" 
                :checked="usuario.status == 'Ativo'" 
                :data-label="status == true ? 'Ativo' : 'Inativo'" 
                v-model="status"
            />

            <Button
                :classes="`is-danger btn-small`" 
                style="margin: -20px; transform: translateY(12px) scale(0.7);"
                :titulo="`Excluir usuário`" 
                :onclick="excluirUsuario"
            />
        </div>

        <div class="btn-control" style="margin: 2rem -2rem 0rem -2rem; width: auto!important; display: flex; justify-content: space-between;">
            <Button
                :classes="`is-danger is-outlined`" 
                :titulo="`Cancelar`" 
                :onclick="cancelar"
            />

            <Button
                v-if="usuario.nomeUsuario != ''"
                :classes="`is-link is-outlined`" 
                :titulo="`Atualizar usuário`" 
                :onclick="atualizarUsuario"
            />

            <Button
                v-else
                :classes="`is-link is-outlined`" 
                :titulo="`Cadastrar usuário`" 
                :onclick="cadastrarUsuario"
            />

        </div>

        <div class="modal" id="modal-confimacao" :class="{ 'is-active': modalExclusao }">
            <div class="modal-background" @click="modalExclusao = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Excluir {{ form.nomeUsuario }}</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalExclusao = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente excluir o usuário?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-link is-outlined" tabindex="-1" @click="modalExclusao = false">Não</button>
                    <button class="button is-danger is-outlined" @click="excluirUsuario(false)">Sim</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DetalhesUsuario",
        props: ['novoUsuario', 'usuario', 'autenticacao', 'listarUsuarios', 'visualizarUsuario', 'removeLoader', 'cancelar'],
        data(){
            return {
                status: this.usuario.status == 'Ativo',
                grupos: [],
                modalExclusao: false,
                form: {
                    nomeUsuario: this.usuario.nomeUsuario,
                    email: '',
                    telefone: '',
                    senha: '',
                    grupo: ''
                },
                validacao: {
                    nomeUsuario: true,
                    email: true,
                    telefone: true,
                    senha: true,
                    grupo: true
                }
            }
        },
        async created() {
            /**
             * Lista os templates
             */
            await this.listarGrupos()  

            /**
             * Popula os dados do usuario
             */
            this.popularDados()
        },
        methods: {
            /**
             * Função para listar os grupos
             */
            async listarGrupos(){
                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'GET',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para buscar os grupos
                 * 
                 * @var {array} aGrupos
                 */
                var aGrupos = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/usuario/grupo/cognito`, oOpcoes)
                    .then(function(response) {
                        return response.json();
                    })
                    .then(function(response) {
                        return response.data
                    })
                    .catch(error => {
                        console.error(error)
                        
                        toastAlert(`${error.codigo} - ${error.descricao}`)
                    })

                /**
                 * Define os grupos no objeto do vue
                 */
                this.grupos = aGrupos
            },
            /**
             * Função para popular os dados do usuario
             */
            popularDados(){          
                if(this.usuario.atributos){
                    this.usuario.atributos.map((oAtributo) => {
                        if(oAtributo.nome == 'phone_number'){
                            this.form.telefone = oAtributo.valor
                        }
                        
                        if(oAtributo.nome == 'email'){
                            this.form.email = oAtributo.valor
                        }
                    })
                }

                if(this.usuario.grupos){
                    this.form.grupo = this.usuario.grupos[0] ?? ''
                }

                /**
                 * Remove o loader
                 */
                this.removeLoader()
            },
            /**
             * Função para cadastrar o usuario
             */
            async cadastrarUsuario(){  
                /**
                 * Realiza a validação dos campos
                 * 
                 * @var {boolean} bValidacao
                 */
                 const bValidacao = this.validarCampos()

                if(!bValidacao){
                    return
                }

                /**
                 * Adiciona o loading no botão
                 */
                document.querySelector('.btn-control button.is-link').classList.add('is-loading')

                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os dados do form
                 * 
                 * @var {object} oForm
                 */
                const oForm = JSON.parse(JSON.stringify(this.form))

                /**
                 * Formata o telefone
                 */
                oForm.telefone = `+${oForm.telefone.replace(/\D/g, "")}`

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'POST',
                    headers: oHeaders,
                    body: JSON.stringify(oForm),
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para alterar os dados do usuario
                 * 
                 * @var {mixed} mCadastro
                 */
                const mCadastro = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/usuario/cognito`, oOpcoes)
                    .then(function(response) {          
                        /**
                         * Remove o loading no botão
                         */
                        document.querySelector('.btn-control button.is-link').classList.remove('is-loading')       
                        
                        if (!response.ok) {
                            return response.json().then(error => {
                                /**
                                 * Remove o loading no botão
                                 */
                                document.querySelector('.btn-control button.is-link').classList.remove('is-loading')
                                
                                if(error.codigo == 'InvalidParameterException'){
                                    toastAlert(`Algum campo está inválido.`)
                                    return false
                                }

                                if(error.codigo == 'InvalidPasswordException'){
                                    toastAlert(`A senha deve conter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula e 1 caractere especial.`)
                                    return false
                                }

                                if(error.codigo == 'UsernameExistsException'){
                                    toastAlert(`O usuário já existe.`)
                                    return false
                                }
                                    
                                toastAlert(`Erro ao cadastrar usuário: ${error.codigo}`)

                                return false
                            });
                        }

                        return response.json()
                    })
                    .then(function(response) {        
                        /**
                         * Remove o loading no botão
                         */
                        document.querySelector('.btn-control button.is-link').classList.remove('is-loading')       
                        
                        if (!response) {
                            return false
                        }

                        return response
                    })

                if(!mCadastro){
                    return
                }

                await this.listarUsuarios()

                toastAlert('Usuário cadastrado com sucesso', 'success')
                
                /**
                 * Remove o loading no botão
                 */
                document.querySelector('.btn-control button.is-link').classList.remove('is-loading')                

                await this.visualizarUsuario(this.form.nomeUsuario)
            },
            /**
             * Função para atualizar o usuario
             */
            async atualizarUsuario(){     
                /**
                 * Realiza a validação dos campos
                 * 
                 * @var {boolean} bValidacao
                 */
                 const bValidacao = this.validarCampos(true)

                if(!bValidacao){
                    return
                }

                /**
                 * Adiciona o loading no botão
                 */
                document.querySelector('.btn-control button.is-link').classList.add('is-loading')

                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os dados do form
                 * 
                 * @var {object} oForm
                 */
                const oForm = JSON.parse(JSON.stringify(this.form))

                /**
                 * Remove o nome do usuario do formulario
                 */
                delete oForm.nomeUsuario

                /**
                 * Formata o telefone
                 */
                oForm.telefone = `+${oForm.telefone.replace(/\D/g, "")}`

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'PUT',
                    headers: oHeaders,
                    body: JSON.stringify(oForm),
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para alterar os dados do usuario
                 * 
                 * @var {mixed} mAtualizar
                 */
                const mAtualizar = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/usuario/cognito/${this.usuario.nomeUsuario}`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }

                        return response
                    })
                    

                if(!mAtualizar){
                    /**
                     * Remove o loading no botão
                     */
                    document.querySelector('.btn-control button.is-link').classList.remove('is-loading')
                        
                    return
                }

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoesStatus
                 */
                const oOpcoesStatus = {  
                    method: 'PATCH',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para alterar o status
                 * 
                 * @var {array} aStatus
                 */
                const aStatus = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/usuario/cognito/${this.usuario.nomeUsuario}/status/${this.status == true ? 'ativar' : 'inativar'}`, oOpcoesStatus)
                    .then(function(response) {
                        return response.json()
                    })
                    .then(function(response) {
                        return response
                    })

                await this.listarUsuarios()

                toastAlert('Usuário atualizado com sucesso', 'success')

                /**
                 * Remove o loading no botão
                 */
                document.querySelector('.btn-control button.is-link').classList.remove('is-loading')
            },
            /**
             * Função para excluir o usuario
             */
            async excluirUsuario(bAbrirModal = true){     
                if(bAbrirModal){
                    this.modalExclusao = true

                    return
                }

                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'DELETE',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para alterar os dados do usuario
                 * 
                 * @var {mixed} mExcluir
                 */
                const mExcluir = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/usuario/cognito/${this.usuario.nomeUsuario}`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }

                        return response
                    })
                    

                if(!mExcluir){                        
                    return
                }

                await this.listarUsuarios()

                this.cancelar()
                this.modalExclusao = false

                toastAlert('Usuário excluído com sucesso', 'success')
            },
            /**
             * Função para validar os campos
             */
            validarCampos(bAtualizacao = false){
                /**
                 * Define a variavel do retorno
                 * 
                 * @var {boolean} bRetornoValidacao
                 * @var {boolean} bRetornoCampoInvalido
                 */
                let bRetornoValidacao = true
                let bRetornoCampoInvalido = true

                Object.keys(this.validacao).map((sCampo) => {
                    if(bAtualizacao){
                        if(sCampo == 'senha' && this.form['senha'] == ''){
                            return
                        }
                    }
                    
                    if(this.form[sCampo] == ''){
                        this.validacao[sCampo] = false
                        bRetornoValidacao = false
                    }

                    if(sCampo == 'nomeUsuario'){
                        if(/\s/.test(this.form[sCampo])){
                            this.validacao[sCampo] = false
                            bRetornoCampoInvalido = false
                            toastAlert("Nome de usuário inválido")
                        }
                    } 

                    if(sCampo == 'email'){
                        if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.form[sCampo].trim())){
                            this.validacao[sCampo] = false
                            bRetornoCampoInvalido = false
                            toastAlert("Email inválido")
                        }
                    }  

                    if(sCampo == 'telefone'){
                        if(!/^\+\d{2}\s\(\d{2}\)\s\d{5}-\d{4}$/.test(this.form[sCampo].trim())){
                            this.validacao[sCampo] = false
                            bRetornoCampoInvalido = false
                            toastAlert("Número de telefone inválido")
                        }
                    }  

                    if(sCampo == 'senha'){
                        if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(this.form[sCampo])){
                            this.validacao[sCampo] = false
                            bRetornoCampoInvalido = false
                            toastAlert(`A senha deve conter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula e 1 caractere especial.`)
                        }
                    }  
                })

                if(!bRetornoValidacao){
                    toastAlert("Campo obrigatório não informado")
                }

                if(!bRetornoCampoInvalido){
                    return false
                }

                return bRetornoValidacao
            }
        }
    };
</script>

<style scoped>
    .novaMensagem{
        margin: 0 auto;
        position: relative;
        width: 44rem;
        background: #fff;
        padding: 1rem 2rem;
        border-radius: 1rem;
        height: fit-content;
    }

    select, .select{
        width: 100%;
    }

    .label{
        margin-top: 0.8rem
    }

    .btn-control{
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
</style>