<template>
    <div>
        <Loader />
    </div>
</template>

<script>
    import axios from "axios"

    export default {
        name: 'ValidarIP',
        data() {
            return {
                validado: sessionStorage.getItem('ipValido') == 1 ? true : false
            }
        },
        mounted() {
            this.validar()
        },
        methods: {
            /**
             * Função para realizar o login na aplicação
             */
             validar() {
                if(!this.validado){
                    window.location.href = `${process.env.VUE_APP_ENDPOINT_AUTH}/login?redirect_uri=${window.location.href}indicadores`
                }

                axios.request({
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/validarIP`
                })
                .then((response) => {
                    sessionStorage.setItem('ipValido', 1)
                    window.location.href = `${process.env.VUE_APP_ENDPOINT_AUTH}/login?redirect_uri=${window.location.href}indicadores`
                })
                .catch((error) => {
                    sessionStorage.setItem('ipValido', 0)
                    window.location.href = '/erro'
                });
            }
        }
    }
</script>